import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import  swal from 'sweetalert';
import { JwtService } from 'src/app/services/Authentication/jwt.service';
import { user } from 'src/app/Models/user';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  isUserLoggedIn:boolean=false;
  currentUserData:user;
  isLoadingLogout:boolean=false;
 

  constructor(private router:Router,private userService:UserService, private jwtService:JwtService) {
  //this.jwtService.destroyToken();
   }
  

  ngOnInit() {
    this.currentUserData=new user();
    this.userService.isUserLoggedIn.subscribe(
      (data:any)=>{
        this.isUserLoggedIn=data;
        if(data==true)
        this.getUserData();
        console.log(data);
       // swal(data);
      }
    )
   

  }


  getUserData()
  {
    this.userService.getCurrentUserData().subscribe(
      (userdata:any) => {
        debugger;
        this.currentUserData = userdata;
        console.log(this.currentUserData);
       // this.currentUserId=this.currentUserData.userId;
      }
    );
  }
  signup()
  {
    //swal("reached");
    this.router.navigate(['/signup']);
  }
  signin()
  {
    this.router.navigate(['/signin']);
  }
  Logout() {
    this.isLoadingLogout=true;
    //localStorage.removeItem("userToken");
    debugger;
    this.userService.purgeAuth();
    this.isLoadingLogout=false;
    this.router.navigate(['/home']);
  }
  navopen:boolean = false
  opennav(){
  var bar = document.getElementById('open');
  if(!this.navopen){

    bar!.style.height = '304px'
    this.navopen = true
  }
  else{
   bar!.style.height = '0px'
   this.navopen = false
  }
  }

  navclose(){
  var bar = document.getElementById('open');

    if(this.navopen){
      bar!.style.height = '0px'
   this.navopen = false
    }
  }

}
