<!-- <div class="aboutus-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="aboutus">
                    <h2 class="aboutus-title">About Us</h2>
                
                    <p class="aboutus-text">Engineer Diaries was started in 2016 as a blog regarding interview experiences, college life, internships etc.</p>
                    <p class="aboutus-text">We provide free tutorial of high quality developed by experts working in the industry on all the lastest in-demand technology.Engineer diaries is on a journey to provide best possible content that matters.</p>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="aboutus-banner">
                    <img style="margin-left: -20px;" src="../../../../assets/images/founder.PNG" alt="">
                    <h6 style="margin-left: 80px;">Priyanka Arora</h6> 
                    <h6 style="margin-left: 80px;">Founder & CEO</h6> 
                </div>
            </div>
            <div class="col-md-5 col-sm-6 col-xs-12">
                <div class="feature">
                    <div class="feature-box">
                        <div class="clearfix">
                           
                            <div class="feature-content">
                                <h4>Original Content</h4>
                                <p>Original and high quality content is what we provide and we won't ever compensate on the quality.</p>
                            </div>
                        </div>
                    </div>
                    <div class="feature-box">
                        <div class="clearfix">
                        
                            <div class="feature-content">
                                <h4>Latest technology</h4>
                                <p>Latest technology, people using in the industry with the structure developed by experienced professionals is what engineer diaries stands for.</p>
                            </div>
                        </div>
                    </div>
                    <div class="feature-box">
                        <div class="clearfix">
                         
                            <div class="feature-content">
                                <h4>Free Content</h4>
                                <p>We have kept the content free because education is everyone's right!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="aboutus container-fluid">
    <div class="about container">
       <div class="wrapper">
            <div class="row">
          <div class="col-12">
                <h2 class="aboutus-title">About Us</h2>
          </div>
        </div>
        <div class="row">
              <p class="aboutus-text">
                  Engineer Diaries provide online live classes on Automation Testing tools like Selenium Webdriver with Java, Selenium with Python,
                  Apache Jmeter, SOAP UI, Appium. We keep on adding new courses and material based on changes in the industry. Engineer Diaries started in 2016 as a blog, since then it has emerged as tutorial site and now 
                  providing training on upcoming latest technologies. We also help the individuals to crack interviews. Everytimes you complete a training from engineer diaries, you would get a training compeletion certificate from us. We are trying to build a name you can 
                  put your trust to. Join us in our journey of enjoying the tech challenges and remain at par with the upcoming technologies. 
              </p>
        </div>
       </div>
</div>
<div class="aboutus container-fluid">
    <div class="about container">
              <div class="wrapper">
        
        <div class="row justify-content-center">
          
                <div class="col-9 imgcol">
               <div class="inneringdiv">
                  <img src="../../../assets/images/found.jpg" alt="">
               </div>
      
          </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-5 designation">
                <h3>Priyanka Arora</h3>
                <p>Founder and CEO</p>
            </div>
        </div>
       </div>
            
        </div>
    </div>
       <div class="aboutus container-fluid color">
        <div class="about container">
       <div class="wrapper">
           <div class="row misrow">
               <div class="col-12">
                <h2 class="aboutus-title">Our Mission</h2>
                   
               </div>
           </div>
           <div class="row justify-content-between">
               <div class="col-md-3 feature-content">
                 <h3 class="icon"><i class="fas fa-lightbulb"></i></h3>
                    <h4>Online Live Classes</h4>
                                <p>Engineer Diaries provide online live classes to connect people across India through our training.
                                    We take immense pride in building a community who wants to challlenge, help and grow themselves.
                                    Growth of an individual, is growth of entire family, and hence nation. We are together with the
                                    country's agenda to UPSKILL INDIA.
                               </p>
               </div>
               <div class="col-md-3 feature-content">
                <h3 class="icon"><i class="fas fa-lightbulb"></i></h3>
                    <h4>High Quality</h4>
                                <p>
                                    We work on to provide the best training out there. We keep on upgrading ourselves on the daily basis.
                                    We want to put across the latest, challenging and helpful material out there that helps you in your
                                    career.
                                </p>
                              
               </div>
               <div class="col-md-3 feature-content">
                 <h3 class="icon"><i class="fas fa-lightbulb"></i></h3>
                   <h4>Placement centric Courses</h4>
                                <p>The aim of training is to take you from start and build your skill set and hence
                                    help you crack interviews. The course are designed to be 100% placement centric
                                    , upgrading the material based on new requirements in the industry. We are building
                                    an institute you can put your trust to!
                                </p>
               </div>
           </div>
       </div>
   </div>
</div>

<!--
<div class="aboutus container-fluid team">
        <div class="about container">
       <div class="wrapper">
           <div class="row misrow">
               <div class="col-12">
                <h2 class="aboutus-title">Meet Our Team</h2>
                   
               </div>
           </div>
          <div class="row justify-content-between">
            <div class="col-md-4">
              <div class="imgdiv"><img src="../../../assets/images/rajat.jpg" alt=""></div>
              <div class="data">
                <h3>Rajat Mondal</h3>
                <p>Web Developer</p>
              </div>
            </div>
            <div class="col-md-4">
               <div class="imgdiv"><img src="../../../assets/images/preity.jpg" alt=""></div>
              <div class="data">
                <h3>Preity Singh</h3>
                <p>Web Developer</p>
              </div>
            </div>
            <!-- <div class="col-lg-3"></div> 
          </div>
      </div>
   </div>
</div>-->

    </div>
