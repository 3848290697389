<div style="margin-top: 100px;">
    <div class="form-group col-md-4">
        <label> Choose File </label>
        <input class="form-control" type="file" id="file-upload" (change)="onFileChange($event)" #fileInput 
        name="data" [(ngModel)]="general.data" #data="ngModel">
      </div>
      <div class="form-group col-md-2">
        <!-- <label> </label> -->
        <button type="button" (click)="uploadDocument('data')" style="margin-top: 23px;" class="btn btn-primary"> Upload Document </button>
      </div>
</div>
