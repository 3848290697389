import { Component, OnInit } from '@angular/core';
import {Meta,Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private meta: Meta,private titleService:Title) { }
  title:string="Engineer Diaries | Software Testing Courses in India";

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.meta.addTag({name: 'keywords', content: 'Software Testing Courses'});
    this.meta.addTag({name: 'description', content: 
    'Engineer Diaries provides Software Testing Courses in India through online classes on Microsoft Teams for freshers and corporates'
  +' and help them in up-skilling as well getting placed in their dream company.'});
  }

}
