import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogService } from 'src/app/services/blog/blog.service';
import  swal from 'sweetalert';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Meta, Title } from '@angular/platform-browser';
import { general } from 'src/app/Models/general';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-singleblog',
  templateUrl: './singleblog.component.html',
  styleUrls: ['./singleblog.component.css'],
  encapsulation: ViewEncapsulation.None,

})
export class SingleblogComponent implements OnInit {

  title:string;
  heading:string;
  blog:any;
  content:string;
  toc:string;
  faq1:string;
  faq2:string;
  faq3:string;
  faq4:string;
  ans1:string;
  ans2:string;
  ans3:string;
  ans4:string;
  author:string;
  date:Date;
  views:number;
  commentsCount:number;
  keywords:string;
  description:string;
  general:general;
  isUserLoggedIn:boolean;
  isLoading:boolean=false;
  isBlogLoading:boolean=false;
  commentList:any;
  postList:any;
  commentButtonVisible:Boolean=true;
  latestList:any;

  content2:string;
  content3:string;
  content4:string;
  content5:string;
  content6:string;
  content7:string;
  content8:string;
  content9:string;
  content10:string;
  content11:string;
  content12:string;
  content13:string;
  content14:string;
  content15:string;
  content16:string;
  content17:string;
  content18:string;
  content19:string;
  content20:string;

  isContent:boolean;
  isContent2:boolean;
  isContent3:boolean;
  isContent4:boolean;
  isContent5:boolean;
  isContent6:boolean;
  isContent7:boolean;
  isContent8:boolean;
  isContent9:boolean;
  isContent10:boolean;
  isContent11:boolean;
  isContent12:boolean;
  isContent13:boolean;
  isContent14:boolean;
  isContent15:boolean;
  isContent16:boolean;
  isContent17:boolean;
  isContent18:boolean;
  isContent19:boolean;
  isContent20:boolean;
  constructor(private blogservice:BlogService, private route: ActivatedRoute, private userService:UserService,
    private meta: Meta,private titleService:Title) {
    //debugger; 
    this.title = this.route.snapshot.params.title;
  }
 

  ngOnInit() {
    this.general=new general();

    this.userService.isUserLoggedIn.subscribe(
      (data)=>
      {
        this.isUserLoggedIn=data;
      }
    )
    this.isBlogLoading=true;

    this.blogservice.getBlog(this.title).subscribe(
      (data)=>
      {
        this.isBlogLoading=false;
        if(data==null)
        {
          swal("Incorrect URL!");
        }
         
         else
         {
           console.log(data);
           this.blog=data;
          this.getCommentList();
          this.getSimilarPost();
           this.getLatestList();
           this.content=this.blog.content;
          
           this.content2=this.blog.content2;
           this.content3=this.blog.content3;
           this.content4=this.blog.content4;
           this.content5=this.blog.content5;
           this.content6=this.blog.content6;
           this.content7=this.blog.content7;
          
           this.content8=this.blog.content8;
           this.content9=this.blog.content9;
           this.content10=this.blog.content10;
           this.content11=this.blog.content11;
           this.content12=this.blog.content12;
           this.content13=this.blog.content13;
           this.content14=this.blog.content14;
           this.content15=this.blog.content15;
           this.content16=this.blog.content16;
           this.content17=this.blog.content17;
           this.content18=this.blog.content18;
           this.content19=this.blog.content19;
           this.content20=this.blog.content20;

           this.isContent=this.blog.isContent;
           this.isContent2=this.blog.isContent2;
           this.isContent3=this.blog.isContent3;
           this.isContent4=this.blog.isContent4;
           this.isContent5=this.blog.isContent5;
           this.isContent6=this.blog.isContent6;
           this.isContent7=this.blog.isContent7;
           this.isContent8=this.blog.isContent8;
           this.isContent9=this.blog.isContent9;
           this.isContent10=this.blog.isContent10;
           this.isContent11=this.blog.isContent11;
           this.isContent12=this.blog.isContent12;
           this.isContent13=this.blog.isContent13;
           this.isContent14=this.blog.isContent14;
           this.isContent15=this.blog.isContent15;
           this.isContent16=this.blog.isContent16;
           this.isContent17=this.blog.isContent17;
           this.isContent18=this.blog.isContent18;
           this.isContent19=this.blog.isContent19;
           this.isContent20=this.blog.isContent20;


           this.toc=this.blog.tableOfContent;
           this.faq1=this.blog.faq1;
           this.faq2=this.blog.faq2;
           this.faq3=this.blog.faq3;
           this.faq4=this.blog.faq4;
           this.ans1=this.blog.ans1;
           this.ans2=this.blog.ans2;
           this.ans3=this.blog.ans3;
           this.ans4=this.blog.ans4;
           this.author=this.blog.author;
           this.date=this.blog.createdOn;
           this.views=this.blog.views;
           this.keywords=this.blog.keywords;
           this.description=this.blog.description;
           this.commentsCount=this.blog.comments;
           this.heading=this.blog.heading;
           this.setMeta();
           console.log(data);
         }
      }

    )
      console.log("reached");
      console.log(this.title);
      console.log(this.keywords);
      console.log(this.description);

    

  
 


    
  
  
  }
  setMeta()
  {
    this.titleService.setTitle(this.heading);
    this.meta.addTag({name: 'keywords', content:this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'robots', content: 'index, follow'});
  }
  comments()
  {
    //debugger;
    this.isLoading=true;
    if(this.isUserLoggedIn==false)
    {
      swal("Please log in First.")
      this.isLoading=false;
    }
      
    else
    {
      if(this.general.data==undefined)
      {
        swal("Please enter comments first");
        this.isLoading=false;
      }
      
    else
    {
      this.general.id=this.blog.id;
      this.blogservice.comments(this.general).subscribe(
        (data)=>
        {
          this.isLoading=false;
          this.general.data="";
          this.commentButtonVisible=false;
          this.getCommentList();

        }
      )
    }
   
  }

    }
    getCommentList()
    {
      this.blogservice.getCommentList(this.blog.id).subscribe(
        (data)=>
        {
          this.commentList=data;
          console.log(data);
        }
      )
    }
    getSimilarPost()
    {
      //debugger;
      this.blogservice.getSimilarPost(this.blog.id).subscribe(
        (data)=>
        {
          console.log("similar");
          console.log(data);
          this.postList=data;
        }
      )
    }
    getLatestList()
    {
      this.blogservice.getLatestList().subscribe(
        (data)=>
        {
          console.log("Latest posts");
          console.log(data);
          this.latestList=data;
        }
      )

    }
   
}
