import { Component, OnInit } from '@angular/core';
import { user } from 'src/app/Models/user';
import { UserService } from 'src/app/services/user.service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/Authentication/jwt.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  constructor(private userService:UserService,private router:Router, private jwtServices:JwtService) { }
  user:user;
  data:any;
  isStateDisabled:boolean=false;
  isEmailCorrect:boolean=true;
  countryAny:any;
  stateAny:any;
  isLoading:boolean=false;

  ngOnInit() {
    this.userService.isUserLoggedIn.next(false);
    this.user=new user();
    this.getEmptyUser();
  }
 
  getEmptyUser()
  {
    this.userService.getEmptyUser().subscribe(
      value=>
      {
        this.data=value;
        this.user=this.data;
        this.countryAny=this.user.country;
        this.stateAny=this.user.state;
        console.log("Empty User");
        console.log(this.user);
      }
    )
  }

signup()
  {
    if(this.user.password!=this.user.confirmPassword)
    {
      swal("Password mismatch!")

    }
     else
     {
       this.isLoading=true;
      this.userService.registerUser(this.user).subscribe(
        (data:any)=>
        {
          this.isLoading=false;
          if(data!=false)
          {
            //successful   console.log(data.accessToken);
            
           
            console.log(data.accessToken);
            this.jwtServices.saveToken(data.accessToken);
            this.userService.isUserLoggedIn.next(true);
            this.userService.setAuth(data);
            swal("Successfully Signed up");
            this.router.navigate(['/home']);
          }
       
          else 
          {
            swal("Your Password is Extremely weak. Make it alphanumeric,special character and add one Capital letter atleast!");

          }
        })
     }
  
  
  }
  checkState()
  {
    if(this.user.countryCode!=100)
    this.isStateDisabled=true;
  }

  visible(){
  document.getElementById('fomr2')!.style.display = 'block'
  document.getElementById('fomr1')!.style.display = 'none'
  }
  back(){
   document.getElementById('fomr2')!.style.display = 'none'
  document.getElementById('fomr1')!.style.display = 'block'
  }

}
