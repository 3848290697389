  
    <div class="container">
      <div class="row justify-content-center">
        <div class="spindiv"> <i *ngIf="isBlogLoading" class="fa fa-spinner fa-spin"></i></div>
          <div class="demo-title">
            <h1>{{heading}}</h1>
     
        </div>
        <div id="toc_container">
            <h4 class="toc_title">Contents</h4>
            <ul class="toc_list">
                <div [innerHTML]="toc">
                </div>
            </ul> 
        </div>
       <div class="speci" class="col-md-5">  <i class="fa fa-pencil" aria-hidden="true"></i> by {{author}}</div>
        <div class="speci" class="col-md-5"> <i class="fa fa-calendar" aria-hidden="true"></i> {{date|date}}</div>
       <div class="speci" class="col-md-5">   <i class="fa fa-eye" aria-hidden="true"></i>{{views}} Views
        </div>
       <div class="col-md-5"><i class="fa fa-comment" aria-hidden="true"></i> {{commentsCount}} </div>
        <div id="sec1">
          <div class="codestyle" [innerHTML]="content" *ngIf="isContent"></div>       
          <div  *ngIf="!isContent">{{content}}</div>
        </div>           
  
        <div id="sec2" >
          <div [innerHTML]="content2" class="codestyle" *ngIf="isContent2"></div>       
          <div  *ngIf="!isContent2">{{content2}}</div>
        </div>
     
        <div id="sec3">
          <div [innerHTML]="content3" class="codestyle" *ngIf="isContent3"></div>       
          <div  *ngIf="!isContent3">{{content3}}</div>
        </div>
    
        <div id="sec4">
          <div [innerHTML]="content4" class="codestyle" *ngIf="isContent4"></div>       
          <div  *ngIf="!isContent4">{{content4}}</div>
        </div>
    
       
        <div class="myimdiv"  [innerHTML]="content5" *ngIf="isContent5"></div>       
        <div  *ngIf="!isContent5">{{content5}}</div>
        <div class="next" [innerHTML]="content6" *ngIf="isContent6"></div>       
        <div  *ngIf="!isContent6">{{content6}}</div>
        <div [innerHTML]="content7" class="codestyle" *ngIf="isContent7"></div>       
        <div  *ngIf="!isContent7">{{content7}}</div>

        <div [innerHTML]="content8" class="codestyle" *ngIf="isContent8"></div>       
        <div  *ngIf="!isContent8">{{content8}}</div>
        <div [innerHTML]="content9" class="codestyle" *ngIf="isContent9"></div>       
        <div  *ngIf="!isContent9">{{content9}}</div>
        <div [innerHTML]="content10" class="codestyle" *ngIf="isContent10"></div>       
        <div  *ngIf="!isContent10">{{content10}}</div>
        <div [innerHTML]="content11" class="codestyle" *ngIf="isContent11"></div>       
        <div  *ngIf="!isContent11">{{content11}}</div>
        <div [innerHTML]="content12" class="codestyle" *ngIf="isContent12"></div>       
        <div  *ngIf="!isContent12">{{content12}}</div>
        <div [innerHTML]="content13" class="codestyle" *ngIf="isContent13"></div>       
        <div  *ngIf="!isContent13">{{content13}}</div>
        <div [innerHTML]="content14" class="codestyle" *ngIf="isContent14"></div>       
        <div  *ngIf="!isContent14">{{content14}}</div>
        <div [innerHTML]="content15" class="codestyle" *ngIf="isContent15"></div>       
        <div  *ngIf="!isContent15">{{content15}}</div>
        <div [innerHTML]="content16" class="codestyle" *ngIf="isContent16"></div>       
        <div  *ngIf="!isContent16">{{content16}}</div>
        <div [innerHTML]="content17" class="codestyle" *ngIf="isContent17"></div>       
        <div  *ngIf="!isContent17">{{content17}}</div>
        <div [innerHTML]="content18" class="codestyle" *ngIf="isContent18"></div>       
        <div  *ngIf="!isContent18">{{content18}}</div>
        <div [innerHTML]="content19" class="codestyle" *ngIf="isContent19"></div>       
        <div  *ngIf="!isContent19">{{content19}}</div>
        <div [innerHTML]="content20" class="codestyle" *ngIf="isContent20"></div>       
        <div  *ngIf="!isContent20">{{content20}}</div> 

        <div>
        </div>
      
        
       
      
            <div class="container">
            
                <h2 class="accordion-heading">Frequently Asked Questions </h2>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading0">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                          {{faq1}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse0" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
                      <div class="panel-body">
                        <p>{{ans1}}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading1">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
                         {{faq2}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
                      <div class="panel-body">
                        <p>{{ans2}}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading2">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
                         {{faq3}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                      <div class="panel-body">
                        <p>{{ans3}}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="heading3">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
                          {{faq4}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
                      <div class="panel-body">
                        <p>{{ans4}}</p>
                      </div>
                    </div>
                  </div>
                </div>
            
            </div>
       
  
        <!--similar posts-->
  <div class="similar">
    <h2 class="accordion-heading">Similar Posts </h2>
     
    <div class="mycards row justify-content-between" *ngFor="let item of postList">
    
        
           
           <div class="col-lg-3">   <img src="{{item.src}}" ></div>
       
          
      <div class="col-lg-7">
        <div class="card-body">
          <h5 class="card-title">{{item.heading}}</h5>
          <div>
            <small>
           
              <i class="fa fa-calendar" aria-hidden="true"></i> {{date|date}}, 
              <i class="fa fa-eye" aria-hidden="true"></i>{{views}} Views,
              <i class="fa fa-comment" aria-hidden="true"></i> {{commentsCount}} 
             </small>
          </div>
          <div><a href="blogs/{{item.title}}" class="btn btn-primary">View Post</a>
           </div>
          <!-- <p class="card-text"><strong>{{item.description}}</strong></p> -->
        
        </div>
      </div>
            
           
          
          
      
     
    </div>
  </div>
   
     
  
        <!--previous comments-->
     <div class="comsec">
      <h2 class="accordion-heading" style="margin-top: 10px;">Comments Section</h2>
      <div *ngFor="let item of commentList">
        <div class="comentcard">
          <div class="card-body">            
           <div class="row justify-content-start">
            
              <div class="col-3 icpn"><i class="fa fa-user-circle card-title" aria-hidden="true"></i></div>
              <div class="col-6 size">
                {{item.fullName}} <br>
                {{item.createdOn|date}}   
              </div>
           </div>   
              
                       
           <div class="row justify-content-center">
            <div class="col-6"><p class="card-text">{{item.comment}}</p></div>
           </div>
          </div>
        </div>      
      </div>

     </div>
       
        <!--Comment section-->
  <div class="comment">
    <form #commentsForm="ngForm"  id="commentsForm">
   
        <div class='row'>
          <textarea id="data"  class="form-control" rows="2" name="data"  #data="ngModel" [(ngModel)]="general.data" 
          placeholder="Leave Us a Comment"></textarea>
        </div>              
        
            <div class="row justify-content-center">
              <div class="col-5"><button *ngIf="commentButtonVisible" type="button" (click)="comments()" 
                class="btn btn-success">Submit</button></div>
            </div>
          
                 
          <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
      
    </form>  
  </div>
                <!-- latest -->
                <div class="latest">
                  <h2 >Latest Posts</h2>
                  <div class="latlist" *ngFor="let item of latestList">
                      <a href="blogs/{{item.title}}">{{item.heading}}</a>
                  </div>
                </div>
   
      </div> 
    </div>

    

    

  

