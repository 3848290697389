      
  <!--search box-->
     
<div class="container searchbar">
  <div class="row justify-content-center">
    <div class="col-8">
 
      <input name="data" type="text" [(ngModel)]="general.data" #data="ngModel" id="data"  placeholder="Search Blogs...">
    
        <button class="btn btn-primary" (click)="search()"><i class="fas fa-search"></i></button>
  </div>  
  </div>
</div>  
    
   <!-- end serach -->

  <div class="container-fluid carddiv">
   
     <div *ngIf="loading" class="row justify-content-center spindiv">
      <div class="col-5"> <i class="fa fa-spinner fa-spin"></i><span> Loading Blogs...</span></div>
     </div>

        <div class="row details-card justify-content-around">                             
            <div  class="col-lg-3" *ngFor="let item of blogList">
                <div class="card-content">
                    <div class="card-img">
                        <img src="{{item.src}}" alt="{{item.title}}">
                    </div>
                    <div class="card-desc">                     
                      <h3>{{item.heading}}</h3>
                        

                        <p>{{item.description}}</p>
                            <a type="button" href="blogs/{{item.title}}" class="mybtn btn btn-outline-info">Read</a>   
                    </div>
                </div>
            </div> 
    </div>  
</div>  
        
 

  <div class="container more">
    <div class="row justify-content-center">
      <div *ngIf="searchmoreloading" class="col-5">
        <i  class="fa fa-spinner fa-spin"></i> <span class="moreblogs"> Loading</span>
      </div>
    
    </div>
    <div class="row justify-content-center">
      <div class="col-5">
        
        <button (click)="onScrollDown()" class="btn btn-outline-success">Read More</button>
      </div>   
    </div>
  </div>
  



 

  

        
    

  
    