import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(private http: HttpClient) { }
  readonly rootUrl = "https://engineerdiaries.com:44553/";

  submitBlog(form)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    return this.http.post(this.rootUrl + 'blog/submitBlog',form,requestOptions);
 

  }
  public getIPAddress()  
  {  
    return this.http.get("https://api.ipify.org/?format=json");  
  }  
  check()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    return this.http.get(this.rootUrl + 'blog/check',requestOptions);
  
 

  }

  search(form)
  {
    const data={
      data:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/search',data,requestOptions);
  }
  getBlog(form)
  {
    const data={
      data:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/getBlog',data,requestOptions);
 

  }
  getTutorialIndex(form)
  {

    const data={
      data:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/getTutorialIndex',data,requestOptions);
 


  }
  getTutorial(form)
  {
    const data={
      data:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/getTutorial',data,requestOptions);
 

  }
  comments(form)
  {

    return this.http.post(this.rootUrl + 'blog/comments', form);
  }
  commentsForTutorial(form)
  {
    return this.http.post(this.rootUrl + 'blog/commentsForTutorial', form);
  }
  getTutorialList()
  {
    // const data={
    //   id:form
    // }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get(this.rootUrl + 'blog/getTutorialList',requestOptions);

  }
  getBlogList(form)
  {
    //debugger;
    const data={
      id:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/getBlogList', data,requestOptions);

  }
  getCommentListForTutorial(form)
  {
    const data={
      id:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/getCommentListForTutorial', data,requestOptions);
  }
 
  
  getCommentList(form)
  {
    const data={
      id:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/getCommentList', data,requestOptions);
  }
  EnquiryForm(form)
  {
    console.log("Reached enquiry form");
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'blog/enquiryForm', form,requestOptions);
  }
 
  UploadImage(formData) {
    
   
    return this.http.post(this.rootUrl + 'blog/UploadImage', formData);
  }

  getSimilarPost(form)
  {
    const data={
      id:form
    }
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.post(this.rootUrl + 'blog/getSimilarPost', data,requestOptions);
  }
  getLatestList()
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get(this.rootUrl + 'blog/getLatestList', requestOptions);
  
  }
}
