export class user
{
    id:number;
    fullName:string;
    dateOfBirth:Date;
    gender:string;
    profession:string;
    countryCode:number;
    stateCode:number;
    phoneNumber:string;
    email:string;
    username:string;
    password:string;
    confirmPassword:string;
    userRole:string; 
    country:any;
    state:any;
   // rememberMe:boolean;

}