
<!-- https://assets10.lottiefiles.com/packages/lf20_hzfmxrr7.json -->
<div class='container-fluid lottiediv'>
  <div class='container'>
   <div class='row justify-content-between'>
    <div class='col-lg-6 formdiv'>
     <div class='myfloatform' id='flotform'>
      <h3>Register to get notified about upcoming batch</h3>
      <form autocomplete="off" #courseRegistrationForm="ngForm"  id="courseRegistrationForm">
        <input class="aniinput" type="text" #fullName="ngModel" name="name" id="myname" placeholder='Enter your FullName..' [(ngModel)]="courseRegistration.fullName" > 
        <input class="aniinput" #email="ngModel" type="email"  name="email" id="myemail" placeholder='Enter your email..'  [(ngModel)] = 'courseRegistration.email'>
        <input class="aniinput" type="number" #phoneNumber="ngModel"  [(ngModel)]="courseRegistration.phoneNumber"  name="number" id="mynumber" placeholder='Enter your WhatsApp Number..' >
        <input class="aniinput" type="text" #profession = 'ngModel' name="Job" id="Job" placeholder='Enter your Current Job..' [(ngModel)] = 'courseRegistration.profession' >
        <label for="course" id="choosecourse" *ngIf='!courseRegistration.course'>Select A Course</label>
        <select class="selectfeature" #course="ngModel"  [(ngModel)]="courseRegistration.course" name="course" id="course" placeholder='Select Course' >
         
          <option style="font-family: 'times new roman'" value="Selenium & Frameworks with Java">Selenium & Frameworks with Java</option>
          <option style="font-family: 'times new roman'" value="Selenium & Frameworks with Python">Selenium & Frameworks with Python</option>
        </select>
        <button class='btn btn-success' type="submit"  (click)='coureseregister()'>Submit</button>
        <div *ngIf="loadingForm" class="spindiv">
          <i  class="fa fa-spinner fa-spin spinner" style="color: black"></i>
          <span>  Loading...</span>
         </div>
      </form></div>
    </div>
    <div class='col-lg-6'><lottie-player src="https://assets10.lottiefiles.com/packages/lf20_hzfmxrr7.json "  background="transparent" speed="1" loop autoplay></lottie-player>
    </div></div>
  </div>
</div>
<div class='offeredcourse container-fluid'>
<div class="container">
   <div class='row'>
    <div class='col-lg-2'>
      <h3>Our Offered <br> Courses</h3>
    </div>
    <div class='col-lg-10'><span> Selenium </span> <span> Jmeter </span> <span> Appium </span><span>ETL</span><span>loadRunner</span><span>Soap UI</span> </div>
    

 </div>
</div>
</div>
  <div class="mycardcontainer container">
    <div class="row searchbar justify-content-center">
      <div class="col-8">
        
        <input name="data" type="text" [(ngModel)]="general.data" #data="ngModel" id="data"  placeholder="Search Tutorials...">
      
          <button class="btn btn-primary" (click)="search()"><i class="fas fa-search"></i></button>
       
      </div>
    </div>
    <!-- <div class="col-sm-9"> -->
     <div *ngIf="loading" class="spindiv">
      <i  class="fa fa-spinner fa-spin spinner"></i>
      <span>  Loading...</span>
     </div>
        <div class="row details-card justify-content-between">                             
            <div  class="col-lg-4 tutcards" *ngFor="let item of blogList">
              <div class="maintut">
                <div class="card-content">
                    <div class="card-img">
                        <img src="{{item.image}}" alt={{item.heading}} >
                    </div>
                    <div class="card-desc">                     
                      <h3>{{item.heading}}</h3>
                        <div class="specs">
                        
                          <i class="fa fa-eye" aria-hidden="true"></i> {{item.views}} Views |
                          <i class="fa fa-comment" aria-hidden="true"></i> {{item.comments}} Comments
                          </div>
                            <div class="specs">
                        
                          
                          <i class="fas fa-calendar" aria-hidden="true"></i> {{item.createdOn | date}}
                          </div>
                        <p>{{item.description}}</p>
                           <a href="{{item.title}}" class="btn btn-outline-success">Read More</a>
                    </div>
                </div>
            </div> 
          </div>
        </div>  
            
        
  </div>
<div class="services container">
  <div class="row justify-content-center">
    <div class="col-12">
      <h3>Numbers Speak Louder</h3>
    </div>
  </div>
    
    <div class="row cardrow justify-content-around">
           <div class="col-md-3 servdiv">

             <div class="wrapper">
        <div class="upper">
          <h3>Students Taught</h3>
        </div>
        <div class="lower">
          <p>150+ students</p>
        </div>
        <div class="circle">
          <i class="fas fa-graduation-cap"></i>
        </div>
      </div>
      </div>
      
      <!--
      <div class="col-md-3 servdiv">

             <div class="wrapper">
        <div class="upper">
          <h3>Title</h3>
        </div>
        <div class="lower">
          <p>Number+</p>
        </div>
        <div class="circle">
          <i class="fas fa-graduation-cap"></i>
        </div>
      </div>
      </div>
      <div class="col-md-3 servdiv">
      <div class="wrapper">
        <div class="upper">
          <h3>Title</h3>
        </div>
        <div class="lower">
          <p>Number+</p>
        </div>
        <div class="circle">
          <i class="fas fa-graduation-cap"></i>
        </div>
      </div>
      </div>-->
    </div>
</div>
<div class="container-fluid testimonials">
  <h1>Testimonials</h1>
<div class="container">
  <swiper
  effect = 'coverflow'
 [autoplay]='{delay:4500}'
  [slidesPerView]="1"
  [spaceBetween]="50"
  [navigation]="true">
  <ng-template swiperSlide>

    <div class="row swiperrow">
      <div class="col-lg-4 swiperimage">
        <img src="../../../assets/images/user2.jpg" alt="userimg">
      </div>
      <div class="col-lg-8 align-items-center">
        <h3>Sanika Pradhan</h3>
        <h5>QA engineer</h5>
        <p>Selenium training provided by Priyanka was very beneficial. She gave personal attention and also explained all the concepts with good examples. This training helped me to boost by confidence in the world of automation testing. </p>
      </div>
    </div>
  </ng-template>
    <ng-template swiperSlide>
      <div class="row swiperrow">
        <div class="col-lg-4 swiperimage">
          <img src="../../../assets/images/user1.PNG" alt="userimg">
        </div>
        <div class="col-lg-8 align-items-center">
          <h3>Sriram Kukkadapu</h3>
        <h5>Works at ThoughtWorks,8+ years Experience</h5>
          
          <p>Training was very good and properly structured from basics to advanced level(developing framework).
            This training is focussed on both learning + interview preparation. And also trainer is very approachable and available all the time to respond on our queries and clarify our doubts.</p>
        </div>
      </div>
    </ng-template>

    
  <ng-template swiperSlide>
  <div class="row swiperrow">
    <div class="col-lg-4 swiperimage">
      <img src="../../../assets/images/round.png" alt="userimg" style="object-fit: contain;">
    </div>
    <div class="col-lg-8 align-items-center">
      <h3>Sidharth Challagundla</h3>
      <h5>Profile Associate QA</h5>

      <p>Notes and interview questions helped a lot.Your patience and support I will never forget it priyanka Thank you soo much, eventhough you are very young in age I really appreciate the level of patience you have.</p>
    </div>
  </div>
</ng-template>
    <ng-template swiperSlide>
      <div class="row swiperrow">
        <div class="col-lg-4 swiperimage">
          <img src="../../../assets/images/round.png" alt="userimg" style="object-fit: contain;">
        </div>
        <div class="col-lg-8 align-items-center">
          <h3>Prajuna</h3>
        <h5>searching for job</h5>
          
          <p>Thankyou so much for the training.Priyanka Mam is doing great job.I get to know about the training from Mr.Sriram via LinkedIn .The help which these people are doing for the job seekers is really amazing.</p>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="row swiperrow">
        <div class="col-lg-4 swiperimage">
          <img src="../../../assets/images/Accenture-logo.jpg" alt="userimg" style="object-fit: contain;">
        </div>
        <div class="col-lg-8 align-items-center">
          <h3>Gowthami R</h3>
        <h5>Senior Test Engineer at Accenture</h5>
          
          <p> Training was good ,we got to know the basics of selenium .Thanks Priyanka for helping wherever required  when stucked with installations ,with any queries.</p>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="row swiperrow">
        <div class="col-lg-4 swiperimage">
          <img src="../../../assets/images/round.png" alt="userimg" style="object-fit: contain;">
        </div>
        <div class="col-lg-8 align-items-center">
          <h3>Madhushree</h3>
        <h5>SDET</h5>
          
          <p>Thank you so much for your training it was very helpful and I joined for  SDET role for selenium and appium testing.</p>
        </div>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="row swiperrow">
        <div class="col-lg-4 swiperimage">
          <img src="../../../assets/images/round.png" alt="userimg" style="object-fit: contain;">
        </div>
        <div class="col-lg-8 align-items-center">
          <h3>Subarna Ghatak</h3>
        <h5>Senior QA Analyst</h5>
          
          <p>
Hi Priyanka you are great. I may not have attended all of your sessions but whatever I have attended I got lot of knowledge and concepts. And last few days I have attended few interviews so questions were from all the topics that you shared.</p>
        </div>
      </div>
    </ng-template>

  </swiper>
</div>

</div>

 



 

  

        
    

  
    