<div class="container contact-form">
   
  <div class="row justify-content-between">
      <div class="col-lg-5">
    
      <lottie-player src="https://assets7.lottiefiles.com/private_files/lf30_tcfhq8vf.json"  background="transparent" speed="1" loop autoplay></lottie-player>
  </div>
  <div class="col-lg-6">
        <form #contactForm="ngForm"  id="contactForm">
        <h3>Drop Us a Message</h3>
      
           
                <div class="form-group">
                    <input class="form-control" name="fullName" [(ngModel)]="user.fullName" #fullName="ngModel" id="fullName" required placeholder="Your Name *"/>
                </div>
                <div class="form-group">
                    <input class="form-control" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" name="emailAddress" [(ngModel)]="user.emailAddress" #emailAddress="ngModel" id="emailAddress" required placeholder="Your Email *"  />
                </div>
                <div class="form-group">
                    <input class="form-control" name="phoneNumber" [(ngModel)]="user.phoneNumber" #phoneNumber="ngModel" id="phoneNumber" required placeholder="Your WhatsApp Number *" minlength="10" />
                </div>
                     <div class="form-group">
                    <textarea class="form-control" name="message" [(ngModel)]="user.message" #message="ngModel" id="message" required placeholder="Your Message *" ></textarea>
                </div>
                <div class="form-group">
                    <button type="submit" (click)="contact()"class="btn btn-primary btn-block" [disabled]="!contactForm.valid"> send Message </button>
                    <i *ngIf="isLoading" class="fa fa-spinner fa-spin" style="font-size:24px"></i>
                </div>
          
           
           
           
      
    </form>
  </div>
  </div>
</div>