import { Component, OnInit } from '@angular/core';
import { email } from 'src/app/Models/email';
import { UserService } from 'src/app/services/user.service';
import  swal from 'sweetalert';
import { Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  constructor(private userService:UserService,private router: Router, private meta: Meta,private titleService:Title) { }
  user:email;
  isLoading:boolean=false;

  ngOnInit() {
    this.user=new email();
    this.titleService.setTitle('Contact Us | Engineer Diaries');
    this.meta.addTag({name: 'robots', content: 'index, follow'});
    this.meta.addTag({name: 'keywords', content:'Contact Us'});
    this.meta.addTag({name: 'description',content: "Contact Engineer Diaries at engineerdiaries@gmail.com. We are currently based in Delhi,India."});
   
  }
  contact()
  {
    this.isLoading=true;
    this.userService.contactForm(this.user).subscribe(
      (data)=>
      {
        this.isLoading=false;
        if(data==true)
        {
          swal("Succesfully Submitted!");
          this.router.navigate(['/home']);
        }
         
        else
        {
          swal("Failure to send now! Please try again later or try direct sending to engineerdiaries@gmail.com");

        }

      }
    )
  }

}
