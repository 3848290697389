import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { general } from 'src/app/Models/general';
import { courseRegistration } from 'src/app/Models/courseregistration'
import { BlogService } from 'src/app/services/blog/blog.service';
import SwiperCore from 'swiper/core';
import swal from 'sweetalert';

import  {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCoverflow
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y,Autoplay, EffectCoverflow]);

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html',
  styleUrls: ['./tutorial.component.less']
})
export class TutorialComponent implements OnInit {
  course:courseRegistration
  blogList:any=null;
  general:general;
  loading:boolean=false;
  loadingForm:boolean=false;
  latestLoading:boolean=false;
  searchmoreloading:boolean=false;
  latestList:any;
  lastindex:number=0;
  calledOnce:boolean=false;
  courseRegistration:courseRegistration;

  constructor(private blogservice:BlogService, private meta: Meta,private titleService:Title) { 
   
  }

  addItems() {
      this.getBlogList(this.lastindex);
  }

  onScrollDown() {
    console.log("scrolled");
    this.searchmoreloading=true;
    this.addItems();
  }



  ngOnInit() {

    this.courseRegistration = new courseRegistration()

    this.general=new general();
   // debugger;
    //check controller works
    this.blogservice.check().subscribe(
      (data)=>
      {
        console.log("yes");
      }
    )
    this.loading=true;
    this.getTutorialList();
   // this.getBlogList(this.lastindex);
    this.titleService.setTitle('Engineer Diaries | Software Testing Courses in India');
    this.meta.addTag({name: 'robots', content: 'index, follow'});
    this.meta.addTag({name: 'keywords', content:'Software Testing Courses'});
    this.meta.addTag({name: 'description',content: 'Engineer Diaries provides Software Testing Courses in India through online classes on Microsoft Teams for freshers and corporates'+
    ' and help them in up-skilling as well getting placed in their dream company.'});
    
  }
  search()
  {
    this.loading=true;
   this.blogservice.search(this.general.data).subscribe(
     (data)=>
     {
       this.loading=false;
       this.blogList=data;
     }
   )
  }
  getTutorialList()
  {
    console.log("entered");
  
    this.blogservice.getTutorialList().subscribe(
      (data:any)=>
      {
        this.loading=false;
       // this.searchmoreloading=false;
        console.log("saved");
        console.log("data");
        console.log(data);
        //this.lastindex=this.lastindex+data.length;
        console.log("count"+this.lastindex);
        //debugger;
        //if(this.lastindex==8)
       // {
          //first time call
          this.blogList=data;
          
        
        //}
        // else{
        //  data.forEach(element => {
         
        //     this.blogList.push(element);
        //   }); 
          
        // }
        console.log("blog");
        console.log(this.blogList);
          
      }
    )
  }
  getBlogList(id:number)
  {
   // debugger;
   console.log("entered");
  
      this.blogservice.getBlogList(id).subscribe(
        (data:any)=>
        {
          this.loading=false;
          this.searchmoreloading=false;
          console.log("saved");
          console.log("data");
          console.log(data);
          this.lastindex=this.lastindex+data.length;
          console.log("count"+this.lastindex);
          //debugger;
          if(this.lastindex==8)
          {
            //first time call
            this.blogList=data;
          
          }
          else{
           data.forEach(element => {
           
              this.blogList.push(element);
            }); 
            
          }
          console.log("blog");
          console.log(this.blogList);
            
        }
      )
     
     
     }
     isEmail(email:string) { 
    const regex="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$";
    if(email.match(regex)){
      return true;
    }
      else {console.log('Email mismatch')
        return false;
  }
     }
  

     coureseregister(){
      this.loadingForm=true;
       console.log(this.courseRegistration)
       if(
        this.courseRegistration.fullName==undefined||
        this.courseRegistration.fullName=='')
        swal("Please fill your name")
        else if(this.courseRegistration.email==undefined||(this.courseRegistration.email!=undefined&&
        this.isEmail( this.courseRegistration.email)==false))
         swal("Please fill the email")
        else  if(this.courseRegistration.phoneNumber==undefined
        ||(this.courseRegistration.phoneNumber!=undefined&&this.courseRegistration.phoneNumber.toString().length!=10) )
        {console.log(this.courseRegistration.phoneNumber.toString().length)
          swal("Please fill the phone number")
        }
        else if(this.courseRegistration.profession==undefined||
        this.courseRegistration.profession=='')
        swal("Please fill the profession")
        else if(this.courseRegistration.course==undefined||
        this.courseRegistration.course==''
        ){
       
      swal("Please select the course")
     }
     else
     {
       console.log("Reached else");
       this.blogservice.EnquiryForm(this.courseRegistration).subscribe(
         (data=>{
           this.loadingForm=false;
           console.log(data);

           swal("We have received your form. Will contact you within 24hrs.");
         })
       )
     }
     } 

    
   
  }
 



