import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './shared/header/header.component';
import { SignupComponent } from './components/signup/signup.component';
import { SigninComponent } from './components/signin/signin.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/footer/about/about.component';
import { ContactComponent } from './components/footer/contact/contact.component';
import { BlogsComponent } from './components/footer/blogs/blogs.component';
import { CreateblogComponent } from './components/blog/createblog/createblog.component';
import { SingleblogComponent } from './components/blog/singleblog/singleblog.component';
import { NewsletterComponent } from './components/footer/newsletter/newsletter.component';
import { AddimageComponent } from './components/blog/addimage/addimage.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { SingletutoailComponent } from './components/singletutoail/singletutoail.component';
import { TutorialindexComponent } from './components/tutorialindex/tutorialindex.component';
import { TestComponent } from './test/test.component';


const routes: Routes = [
  {
    path: 'signup',component: SignupComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: '', redirectTo:'home',pathMatch:'full'
  },
  {
    path: 'about', component: AboutComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'blogs', component: BlogsComponent
  },
  {
    path: 'createblog', component: CreateblogComponent
  },
  {
    path: 'blogs/:title', component: SingleblogComponent
  }
    ,
  {
    path: 'tutorial/:title', component: SingletutoailComponent
  },
  {
    path: 'addimage', component: AddimageComponent
  },
  {
    path: 'test', component: TestComponent
  },
  {
    path: 'newsletter', component: NewsletterComponent
  },
  {
    path: 'signin', component: SigninComponent
  },
  {
    path: 'tutorial', component: TutorialComponent
  },
 {
   path: ':tutorial', component: TutorialindexComponent
  }
  


 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

