
    <div class="container-fluid mynav" >
        <div *ngIf="!isUserLoggedIn" class="row big justify-content-between navbar"> 
            <div class="col-2"><span>Engineer Diaries</span> </div>
            <div class="col-1"><a routerLink='/home' routerLinkActive='active' >Home</a></div>
            <div class="col-1"><a routerLink='/about'>About</a></div>
            <div class="col-1"><a routerLink='/newsletter'>News Letter</a></div>
            
            <div class="col-1"><a [routerLink]="['/contact']">Contact Us</a></div>
            <div class="col-1"><a routerLink='/blogs'>Blogs</a></div>         
            <div class="col-1">                       
                <a (click)="signup()">Sign Up</a>

                   
            </div>

            <div class="col-1">
                <a (click)="signin()" >Sign In</a>  
            </div>
           
       </div>
       <div class="row short justify-content-between" *ngIf="!isUserLoggedIn">
        <div class="col-3"><span>Logo</span></div>
        <div class="col-3" (click)='opennav()'><i class="fas fa-bars"></i></div>    
    </div>

 
      </div>

         <div class="foldnav container-fluid" id="open">
        <div class="row">
            <div class="col-12" ><a (click)='navclose()' routerLink='/home' routerLinkActive='active'>Home</a></div>
            <div class="col-12"><a (click)='navclose()'  routerLink='/about'>About</a></div>
            <div class="col-12"><a (click)='navclose()'  routerLink='/newsletter'>News Letter</a></div>
            <div class="col-12"><a (click)='navclose()'  [routerLink]="['/contact']">Contact Us</a></div>
            <div class="col-12"><a (click)='navclose()'  routerLink='/blogs'>Blogs</a></div>
            <div class="col-12"> <a (click)='navclose()'  (click)="signup()">Sign Up</a></div>
            <div class="col-12">   <a (click)='navclose()'  (click)="signin()" >Sign In</a>  </div>
        </div>
    </div>

      
        <div *ngIf="isUserLoggedIn" class="row justify-content-center navbar">
           <div class="col-4">
            <button class="btn btn-primary dropdown-toggle" style="margin-left: 4px;margin-bottom: 8px;"type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-user" ></i>{{currentUserData.fullName}} 
            </button>
           </div>
          <div class="col-4">
            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="Logout()">
                    <i class="fa fa-fw fa-power-off"></i> Log Out
                </a>
                <i *ngIf="isLoadingLogout" class="fa fa-spinner fa-spin" style="font-size:24px"></i>                       
            </div>
          </div>
        </div>
  
   
   
  

    


        




