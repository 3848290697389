<div id="page-wrapper">      
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8">
          <div class="demo-title">
            <h1>{{heading}}</h1>
         </div>
         <!-- <div id="toc_container">
            <p class="toc_title">Contents</p>
           
                <div [innerHTML]="toc">
                </div>
            
          </div> -->
                
          <div class="sec0" style="margin-top: 50px;" [innerHTML]="content" ></div>       
        
  
        <!--previous comments-->
       
       
       
        </div>
        </div> 
    </div>
</div>
    

    

  

