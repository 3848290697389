import { Component, OnInit } from '@angular/core';
import { user } from 'src/app/Models/user';
import { UserService } from 'src/app/services/user.service';
import  swal from 'sweetalert';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  constructor(private userService:UserService, private meta: Meta,private titleService:Title) { }
  user:user;

  ngOnInit() {
    this.user=new user();
    this.titleService.setTitle('Newsletter | Engineer Diaries');
    this.meta.addTag({name: 'robots', content: 'index, follow'});
    this.meta.addTag({name: 'keywords', content:'Newsletter'});
    this.meta.addTag({name: 'description',content: "Newsletter subsciption Engineer Diaries. Sent in your emailid."});
   }
  newsletter()
  {
    this.userService.newsletter(this.user).subscribe(
      (data)=>
      {
        if(data==true)
        {
           swal("You have successfully subscribed to newsletter");

        }
        else
        {
           swal("This email address has already subscribed our newsletter");

        }
      }
    )

  }

}
