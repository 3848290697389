import { Component, OnInit } from '@angular/core';
import { BlogService } from 'src/app/services/blog/blog.service';
import { general } from 'src/app/Models/general';
import  swal from 'sweetalert';
import { Meta, Title } from '@angular/platform-browser';



@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  blogList:any;
  general:general;
  loading:boolean=false;
  latestLoading:boolean=false;
  searchmoreloading:boolean=false;
  latestList:any;
  lastindex:number=0;
  calledOnce:boolean=false;

  constructor(private blogservice:BlogService, private meta: Meta,private titleService:Title) { 
   
  }

  addItems() {
      this.getBlogList(this.lastindex);
  }

  onScrollDown() {
    console.log("scrolled");
    this.searchmoreloading=true;
    this.addItems();
  }



  ngOnInit() {
    this.general=new general();
   // debugger;
    //check controller works
    this.blogservice.check().subscribe(
      (data)=>
      {
        console.log("yes");
      }
    )
    this.loading=true;
    this.getBlogList(this.lastindex);
    this.titleService.setTitle('Engineer Diaries | Software Testing Courses in India');
    this.meta.addTag({name: 'robots', content: 'index, follow'});
    this.meta.addTag({name: 'keywords', content:'Software Testing Courses'});
    this.meta.addTag({name: 'description',content: 'Engineer Diaries provides Software Testing Courses in India through online classes on Microsoft Teams for freshers and corporates'+
    ' and help them in up-skilling as well getting placed in their dream company.'});
  }
  search()
  {
    this.loading=true;
   this.blogservice.search(this.general.data).subscribe(
     (data)=>
     {
       this.loading=false;
       this.blogList=data;
     }
   )
  }
  getBlogList(id:number)
  {
   // debugger;
   console.log("entered");
  
      this.blogservice.getBlogList(id).subscribe(
        (data:any)=>
        {
          this.loading=false;
          this.searchmoreloading=false;
          console.log("saved");
          console.log("data");
          console.log(data);
          this.lastindex=this.lastindex+data.length;
          console.log("count"+this.lastindex);
          //debugger;
          if(this.lastindex==8)
          {
            //first time call
            this.blogList=data;
          
          }
          else{
           data.forEach(element => {
           
              this.blogList.push(element);
            }); 
            
          }
          console.log("blog");
          console.log(this.blogList);
            
        }
      )
     
     
     }
   
  }
 


