import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { general } from 'src/app/Models/general';
import { BlogService } from 'src/app/services/blog/blog.service';
import { UserService } from 'src/app/services/user.service';
import  swal from 'sweetalert';


@Component({
  selector: 'app-tutorialindex',
  templateUrl: './tutorialindex.component.html',
  styleUrls: ['./tutorialindex.component.less']
})
export class TutorialindexComponent implements OnInit {

  title:string;
  heading:string;
  blog:any;
  content:string;
  toc:string;
  author:string;
  date:Date;
  views:number;
  commentsCount:number;
  keywords:string;
  description:string;
  general:general;
  isUserLoggedIn:boolean;
  isLoading:boolean=false;
  isBlogLoading:boolean=false;
  commentList:any;
  postList:any;
  commentButtonVisible:Boolean=true;
  latestList:any;

  constructor(private blogservice:BlogService, private route: ActivatedRoute, private userService:UserService,
    private meta: Meta,private titleService:Title) {
    //debugger; 
    this.title = this.route.snapshot.params.tutorial;
  }
 

  ngOnInit() {
    this.general=new general();

    
    this.isBlogLoading=true;

    this.blogservice.getTutorialIndex(this.title).subscribe(
      (data)=>
      {
        this.isBlogLoading=false;
        if(data==null)
        {
         // swal("Incorrect URL!");
        }
         
         else
         {
           console.log(data);
           this.blog=data;
          
          
           this.toc=this.blog.toc;
           this.content=this.blog.content;
           this.date=this.blog.createdOn;
           this.views=this.blog.views;
           this.keywords=this.blog.keywords;
           this.description=this.blog.description;
           this.commentsCount=this.blog.comments;
           this.heading=this.blog.heading;
           this.setMeta();
           console.log(data);
         }
      }

    )
      console.log("reached");
      console.log(this.title);
      console.log(this.keywords);
      console.log(this.description);

    

  
 


    
  
  
  }
  setMeta()
  {
    this.titleService.setTitle(this.heading);
    this.meta.addTag({name: 'keywords', content:this.keywords});
    this.meta.addTag({name: 'description', content: this.description});
    this.meta.addTag({name: 'robots', content: 'index, follow'});
  }
  comments()
  {
    //debugger;
    this.isLoading=true;
    if(this.isUserLoggedIn==false)
    {
      swal("Please log in First.")
      this.isLoading=false;
    }
      
    else
    {
      if(this.general.data==undefined)
      {
        swal("Please enter comments first");
        this.isLoading=false;
      }
      
    else
    {
      this.general.id=this.blog.id;
      this.blogservice.commentsForTutorial(this.general).subscribe(
        (data)=>
        {
          this.isLoading=false;
          this.general.data="";
          this.commentButtonVisible=false;
         

        }
      )
    }
   
  }

    }
   
   
   
}



