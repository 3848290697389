<div class="container">
  <div class="row justify-content-between">
  
    <div class="col-lg-6">
      
 
    
       
        <h4>Sign up</h4>
   
   
    <form #signupForm="ngForm"  id="signupForm">
       
            <div id="fomr1"  class="wrapper1">
              <div class="form-group">
                <label>Full Name<span style="color: red;">*</span></label>
                <input class="form-control" name="fullName" [(ngModel)]="user.fullName" #fullName="ngModel" id="fullName" required>       
            </div> <!-- form-group end.// -->
            <div class="form-group">
                <label>Date Of Birth<span style="color: red;">*</span></label>
                <input type="date" class="form-control" name="dateOfBirth" [(ngModel)]="user.dateOfBirth" #dateOfBirth="ngModel" id="dateOfBirth" required >          
            </div> <!-- form-group end.// -->
      
     
          <div class="form-group">
              <label>Mobile No<span style="color: red;">*</span></label>
              <input  class="form-control" name="phoneNumber" [(ngModel)]="user.phoneNumber" #phoneNumber="ngModel" id="phoneNumber" required minlength="10">       
          </div> <!-- form-group end.// -->
          <div class="form-group">
              <label>Email<span style="color: red;">*</span></label>
              <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" class="form-control" name="email"  [(ngModel)]="user.email" #email="ngModel" id="email" required >          
          </div> <!-- form-group end.// -->
    
 
            <div class="form-group">
                <label>Gender<span style="color: red;">*</span></label>
                <select class="form-control" name="gender"  [(ngModel)]="user.gender" #gender="ngModel" id="gender" required>
                  <option  value="Male">Male</option>
                  <option  value="Female">Female</option>
                  <option  value="Other">Other</option>
                </select>
            </div>
            <button class="btn next btn-outline-primary" (click)='visible()'>Next</button>
            </div>

            <div id="fomr2" class="wrapper2">
              <div class="form-group">
                <label>Profession<span style="color: red;">*</span></label>
                <input class="form-control" name="profession"  [(ngModel)]="user.profession" #profession="ngModel" id="profession">
            </div>
       
      
            <div class="form-group">
                <label>Country<span style="color: red;">*</span></label>
                <select class="form-control" name="countryCode" (change)="checkState()" [(ngModel)]="user.countryCode" #countryCode="ngModel" id="countryCode" required >
                    <option *ngFor="let item of countryAny" value={{item.id}}>{{item.name}}</option>
                </select>
            </div>
            <div class="form-group" *ngIf="!isStateDisabled">
                <label>State<span style="color: red;">*</span></label>
                <select class="form-control" name="stateCode"  [(ngModel)]="user.stateCode" #stateCode="ngModel" id="stateCode" required>
                    <option *ngFor="let item of stateAny" value={{item.id}}>{{item.name}}</option>
                </select>            
            </div>
            <div class="form-group" *ngIf="isStateDisabled">
              <label>State<span style="color: red;">*</span></label>
              <select class="form-control" name="stateCode"  [(ngModel)]="user.stateCode" #stateCode="ngModel" id="stateCode" disabled>
                  <option *ngFor="let item of stateAny" value={{item.id}}>{{item.name}}</option>
              </select>            
          </div>
       
      
            <div class="form-group">
                <label>Password<span style="color: red;"> Minimum 6 length*</span></label>
                <input class="form-control" type="password" name="password"  [(ngModel)]="user.password" #password="ngModel" id="password"  minlength="6" required>
            </div>
            <div class="form-group">
                <label>Confirm Password<span style="color: red;">*</span></label>
                <input class="form-control" type="password" name="confirmPassword"  [(ngModel)]="user.confirmPassword" #confirmPassword="ngModel" id="confirmPassword" required >                 
            </div>
       
        
       
       
      
          <button class="bkbtn btn btn-outline-primary" (click)='back()'>Back</button>
            <button type="submit" (click)="signup()"class="rgbtn btn btn-primary " [disabled]="!signupForm.valid" > Register  </button>
            <i *ngIf="isLoading" class="fa fa-spinner fa-spin" style="font-size:24px"></i>
       

               <small class="text-muted" style="text-align: center;">By clicking the 'Sign Up' button, you confirm that you accept our <br> Terms of use and Privacy Policy.</small>                                          
            </div>
                
   
    </form>
  
  <div class="border-top card-body text-center">Have an account? <a routerLink='/signin'>Log In</a></div>
   

    
   
    </div>
      <div class="col-lg-5">
      <lottie-player src='https://assets1.lottiefiles.com/packages/lf20_hbl5xthi.json' autoplay speed='1' loop></lottie-player>
    </div>
  </div>
</div>