<div id="page-wrapper">      
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-8">
          <div class="demo-title">
            <h1>{{heading}}</h1>
             <small class="text-muted">
               <!-- <i class="fa fa-pencil" aria-hidden="true"></i> by {{author}},   -->
               <i class="fa fa-calendar" aria-hidden="true"></i> {{date|date}}, 
               <i class="fa fa-eye" aria-hidden="true"></i>{{views}} Views,
               <i class="fa fa-comment" aria-hidden="true"></i> {{commentsCount}} 
              </small>
        </div>
         <div id="toc_container">
            <p class="toc_title">Contents</p>
           
                <div [innerHTML]="toc">
                </div>
            
          </div>
                  <!-- <li><a href="/tutorial/selenium-webdriver-tutorial/#sec1">1 First Point Header</a> -->
                      <!-- <ul>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#sec1">1.What is Selenium Testing?</a></li>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#sec2">2.Selenium Web driver vs Selenium Remote Control</a></li>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#sec3">3.Why to use the Selenium testing tool?</a></li>
                         <li><a href="/tutorial/selenium-webdriver-tutorial/#sec4">4.Selenium Webdriver vs Selenium IDE vs Selenium Grid</a></li>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#sec5">5.Selenium WebDriver Introduction</a></li>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#sec6">6.Why should you learn selenium webdriver?</a></li>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#conclusion">7.Conclusion</a></li>
                        <li><a href="/tutorial/selenium-webdriver-tutorial/#faq">8.FAQ</a></li>
                      </ul> -->


<!--                        
                        <li><a href="/blogs/indian-recipes-hostel-cooking-ideas/#sec7">5. Internship</a></li>
                        
                       

                        <li><a href="/blogs/engineering-entrance-exams-list-2020/#sec6">4. BITS</a></li>
                        <li><a href="/blogs/engineering-entrance-exams-list-2020/#sec7">5.UPTU</a></li>
                        <li><a href="/blogs/engineering-entrance-exams-list-2020/#sec8">6. SRM university</a></li>
                        <li><a href="/blogs/engineering-entrance-exams-list-2020/#faq">7.FAQ</a></li>


                        <li><a href="/blogs/Second-semester-college-preparation-tips/#sec8">6.Attending tech fests of colleges</a></li>
                        <li><a href="/blogs/Second-semester-college-preparation-tips/#sec9">7.Internship</a></li>
                        <li><a href="/blogs/Second-semester-college-preparation-tips/#sec10">8.Studies in second semester</a></li>
                        <li><a href="/blogs/Second-semester-college-preparation-tips/#sec11">9.What about your interest in music/writing/modelling etc</a></li>
                        <li><a href="/blogs/Second-semester-college-preparation-tips/#sec12">10. Do look out for yourself</a></li>

                    </ul>   -->
                    <!-- </li> 
                    <!-- <li><a routerlink="#Second_Point_Header">2 Second Point Header</a></li>
                    <li><a href="/blogs/a/#Third_Point_Header">3 Third Point Header</a>c</li> 
            </ul> 
        </div> 
        <i *ngIf="isBlogLoading" class="fa fa-spinner fa-spin" style="font-size: 35px; margin-top: 10px; margin-left: 15%;"></i> -->
       
          <div class="sec0 " style="margin-top: 50px;" [innerHTML]="content | safeHtml" ></div>       
          <!-- <div  >{{content}}</div> -->
            
        
        <!-- <section id="faq"style="margin-left: -15px;"class="accordion-section clearfix mt-3" aria-label="Question Accordions">
            <div class="container">
            
                <h2 class="accordion-heading">Frequently Asked Questions </h2>
                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                  <div class="panel panel-default">
                    <div class="panel-heading p-3 mb-3" role="tab" id="heading0">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse0" aria-expanded="true" aria-controls="collapse0">
                          {{faq1}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse0" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading0">
                      <div class="panel-body px-3 mb-4">
                        <p>{{ans1}}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="panel panel-default">
                    <div class="panel-heading p-3 mb-3" role="tab" id="heading1">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse1" aria-expanded="true" aria-controls="collapse1">
                         {{faq2}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading1">
                      <div class="panel-body px-3 mb-4">
                        <p>{{ans2}}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="panel panel-default">
                    <div class="panel-heading p-3 mb-3" role="tab" id="heading2">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse2" aria-expanded="true" aria-controls="collapse2">
                         {{faq3}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading2">
                      <div class="panel-body px-3 mb-4">
                        <p>{{ans3}}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="panel panel-default">
                    <div class="panel-heading p-3 mb-3" role="tab" id="heading3">
                      <h3 class="panel-title">
                        <a class="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href="#collapse3" aria-expanded="true" aria-controls="collapse3">
                          {{faq4}}
                        </a>
                      </h3>
                    </div>
                    <div id="collapse3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading3">
                      <div class="panel-body px-3 mb-4">
                        <p>{{ans4}}</p>
                      </div>
                    </div>
                  </div>
                </div>
            
            </div>
        </section>
   -->
        <!--similar posts-->
        
     
  
        <!--previous comments-->
        <h2 class="accordion-heading" style="margin-top: 30px;">Comments Section:</h2>
        <div *ngFor="let item of commentList">
          <div class="card blue" style=" width:100%; height:auto;">
            <div class="card-body">            
              <h3 class="card-title"> 
                <i class="fa fa-user-circle card-title" aria-hidden="true"></i>
                  {{item.fullName}}
                </h3>
                  <i class="fa fa-calendar" aria-hidden="true"></i> {{item.createdOn|date}}             
              <p class="card-text">{{item.comment}}</p>
            </div>
          </div>      
        </div>
       
        <!--Comment section-->
        <form #commentsForm="ngForm"  id="commentsForm">
          <div class="form-group row" style="margin-right: 10px; margin-top: 5px; margin-left: 3px;">
            <div class="col-md-12">
              <textarea id="data"  class="form-control" rows="4" name="data"  #data="ngModel" [(ngModel)]="general.data" cols="70"
              placeholder="Comments" ></textarea>
            </div>              
                <div class="col-md-10"></div>
                <div class="col-md-2">
                  <button *ngIf="commentButtonVisible" type="button"  (click)="comments()" style="margin-top: 10px;" 
                  class="btn btn-success"  >Submit</button>
                </div>
              
                     
              <i *ngIf="isLoading" class="fa fa-spinner fa-spin" style="font-size: 35px; margin-top: 10px; margin-left: 15%;"></i>
           </div>
        </form>  
        </div>
        </div> 
    </div>
</div>
    

    

  

