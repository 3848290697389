import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { user } from '../Models/user';
import { BlogService } from '../services/blog/blog.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.less']
})
export class TestComponent implements OnInit {

  constructor(private blogservice:BlogService,private http: HttpClient,private titleService:Title) { 
    this.titleService.setTitle("abcd");
  }
user:user
  ngOnInit(): void {
  //  this.getIp();
  // this.http.get<{ip:string}>('https://jsonip.com')
  //   .subscribe( data => {
  //     console.log('th data', data);
  //     alert(data);
  //   })
  //   this.user=new user();
   }
  getIp()
  {
    this.blogservice.getIPAddress().subscribe(
    //(data)=>
    //{
     // var result=data;
     // alert(result);
   // }
    )
  
  }
  alert()
  {
    alert("Hi! This is alert message!");
  }
  confirm()
  {
    confirm("DO you confirm?")
  }
  alertPrompt()
  {
    alert(this.user.fullName);
  }
  prompt()
  {
    var ans=prompt("Enter your name");
    alert(ans);
  }

}
