<section class="newsletter container-fluid">
    <div class="container">
    <div class="row justify-content-between">
    <div class="col-lg-5">
       
            <h5>SUBSCRIBE TO OUR NEWSLETTER</h5>
                <p>Stay-Up-To-Date with new tutorials , selected articles , and many more From 
                </p><b>Engineer Diaries</b>
            
            <form #newsForm="ngForm" class='myform'  id="newsForm" autocomplete="off">
               
             <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" name="email" [(ngModel)]="user.email" #email="ngModel" id="email" required placeholder="Enter your Email Address" autocomplete='off'>
                   
                    <button class="btn btn-info" (click)="newsletter()" [disabled]="!newsForm.valid">Subscribe Now</button>
         
            </form>
      
    
    </div>
    <div class="col-lg-5">

        <lottie-player src="https://assets4.lottiefiles.com/packages/lf20_vEfHlN.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
        
    </div>
    </div>
    </div>
    </section>