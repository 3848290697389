import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor( private meta: Meta,private titleService:Title) { }

  ngOnInit() {
    this.titleService.setTitle('About Us | Engineer Diaries');
    this.meta.addTag({name: 'robots', content: 'index, follow'});
    this.meta.addTag({name: 'keywords', content:'About Us'});
    this.meta.addTag({name: 'description',content: "About Engineer Diaries was started in 2016 by Priyanka Arora, now alumini of GGSIPU college 2019."});
 
  }
  
}
