import {TransferHttpCacheModule} from '@nguniversal/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { SignupComponent } from './components/signup/signup.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SigninComponent } from './components/signin/signin.component';
import { HomeComponent } from './components/home/home.component';
import { InterecptorService } from './services/Authentication/interecptor.service';
import { AboutComponent } from './components/footer/about/about.component';
import { ContactComponent } from './components/footer/contact/contact.component';
import { BlogsComponent } from './components/footer/blogs/blogs.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CreateblogComponent } from './components/blog/createblog/createblog.component';
import { SingleblogComponent } from './components/blog/singleblog/singleblog.component';
import { NewsletterComponent } from './components/footer/newsletter/newsletter.component';
import { AddimageComponent } from './components/blog/addimage/addimage.component';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { SingletutoailComponent } from './components/singletutoail/singletutoail.component';
import { TutorialindexComponent } from './components/tutorialindex/tutorialindex.component';
import { TestComponent } from './test/test.component';
import { SafeHtmlPipe } from './pipe';
import  {  CUSTOM_ELEMENTS_SCHEMA } from'@angular/core';
import { SwiperModule } from 'swiper/angular';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SignupComponent,
    SigninComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    BlogsComponent,
    CreateblogComponent,
    SingleblogComponent,
    NewsletterComponent,
    AddimageComponent,
    TutorialComponent,
    SingletutoailComponent,
    TutorialindexComponent,
    TestComponent,
    SafeHtmlPipe

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
   AngularEditorModule,
   ReactiveFormsModule,
   SwiperModule
  
  
  ],
  //exports: [SignupComponent], 
  //entryComponents: [SignupComponent],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: InterecptorService,
    multi: true

  }
   
  ],
  schemas:[ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
