import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import  { Observable } from "rxjs";
//import { UserService } from "../shared/services/user.service";

import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import { map, filter, tap } from 'rxjs/operators';
//import  swal from 'sweetalert';

@Injectable({
  providedIn: 'root'
})
export class InterecptorService {

  constructor(private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   // swal("reached!");
    if (req.headers.get('No-Auth') == "True")
      {
       // swal("one");
        return next.handle(req.clone());
      } 

      if(typeof window !== 'undefined')
      {
        if (localStorage.getItem('userToken') != null) {
          const clonedreq = req.clone({
              headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
          });
          return next.handle(clonedreq).pipe(
              tap(
              succ => { },
              err => {
                  if (err.status === 401)
                      this.router.navigateByUrl('/home');
              }
              ));
  
          // return next.handle(clonedreq).pipe(
          //     tap(event => {
          //       if (event instanceof HttpResponse) {
          //         const elapsed = Date.now() - started;
          //         console.log(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
          //       }
          //     }, error => {
          //       console.error('NICE ERROR', error)
          //     })
          //   )
  
      }
      else {
       // swal("two");
          this.router.navigateByUrl('/home');
      }
      }
   
}
}
