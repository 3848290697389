<div class="container-fluid">
  <div class="container">
    <div class="row justify-content-between">
   
    <div class="col-lg-5 formdiv align-self-center">
 
       
          
            <h4>Sign In</h4>
    
   
    <form autocomplete="off" #signinForm="ngForm"  id="signinForm">
       
        
          <div class="form-group">
             <!--  <label>Email<span style="color: red;">*</span></label> -->
              <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" class="form-control" name="email" [(ngModel)]="user.email" #email="ngModel" id="email" required placeholder="Email*">       
          </div> 
       
                  <div class="form-group">
              <!-- <label>Password<span style="color: red;">*</span></label> -->
              <input type="password" class="form-control" name="password"  [(ngModel)]="user.password" #password="ngModel" id="password" required placeholder="Password*">          
          </div> 
      
        
       
       
         
            <div class="form-group">
            <button type="submit" (click)="signin()"class="btn btn-primary btn-block" [disabled]="!signinForm.valid" > Sign In  </button>
            </div>
            <div class="form-group">
                <i *ngIf="isLoading" class="fa fa-spinner fa-spin"></i>
            </div>
        
    </form>

    <div class="Dont">Dont' have an account? <a routerLink='/signup'>Sign Up</a></div>
    
    </div> 
       <div class="col-lg-5">
        <lottie-player src="https://assets3.lottiefiles.com/packages/lf20_yupefrh2.json"  background="transparent"  speed="1" loop autoplay></lottie-player>
      </div>
    </div>

  </div>
</div>

    