<button (click)="alert()" id="alert" type="button" class="btn btn-primary" style="width:80px;margin-bottom: 5px;">Alert</button>
<button (click)="confirm()" id="confirm" type="button" class="btn btn-light" style="width:80px;margin-left: 5px;margin-bottom: 5px;">Confirm</button> 
<button (click)="prompt()"class="btn btn-success" id="prompt"  type="button"   style="width:80px;margin-left: 5px;margin-bottom: 5px;"
 >Prompt </button>
<div id="messageModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">  
      <div class="modal-content" >
        <div class="modal-body">
            <label>Name<span style="color: red;">*</span></label>
                <input class="form-control"  class="abc"  >
            
        </div>
        <div class="modal-footer">
          <button type="button" id="alert1" (click)="alertPrompt()" class="btn btn-default" style="width:150px;"
           >Submit</button>
  
        </div>
      </div>
  </div>    
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6">
    <div class="card">
    <header class="card-header">
        <a routerLink='/signin'class="float-right btn btn-outline-primary mt-1">Log in</a>
        <h4 class="card-title mt-2">Sign up</h4>
    </header>
    <article class="card-body">
    <form #signupForm="ngForm"  id="signupForm">
        <div class="form-row">
            <div class="col form-group">
                <label>Full Name<span class="green">*</span></label>
                <input class="form-control" name="fullName" [(ngModel)]="user.fullName" #fullName="ngModel" id="fullName" >       
            </div> <!-- form-group end.// -->
            <div class="col form-group">
                <label>Date Of Birth<span style="color: red;">*</span></label>
                <input type="date" class="form-control" name="dateOfBirth" [(ngModel)]="user.dateOfBirth" #dateOfBirth="ngModel" id="dateOfBirth" required >          
            </div> <!-- form-group end.// -->
        </div> <!-- form-row end.// -->
        <div class="form-row">
          <div class="col form-group">
              <label>Mobile No<span style="color: red;">*</span></label>
              <input  class="form-control" name="phoneNumber" [(ngModel)]="user.phoneNumber" #phoneNumber="ngModel"   minlength="10">       
          </div> <!-- form-group end.// -->
          <div class="col form-group">
              <label>Email<span style="color: red;">*</span></label>
              <input pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" class="form-control" name="email"  [(ngModel)]="user.email" #email="ngModel" id="email" required >          
          </div> <!-- form-group end.// -->
      </div> <!-- form-row end.// -->
         <!--   <div class="col form-group">
                <label>Gender<span style="color: red;">*</span></label>
                <select class="form-control" name="gender"  [(ngModel)]="user.gender" #gender="ngModel" id="gender" required>
                  <option  value="Male">Male</option>
                  <option  value="Female">Female</option>
                  <option  value="Other">Other</option>
                </select>
            </div>-->
            <div class="col form-group">
                <label>Profession<span style="color: red;">*</span></label>
                <input class="form-control" name="profession"  [(ngModel)]="user.profession" #profession="ngModel" id="profession">
            </div>
     <!--   </div>-->
        <div class="form-row">
            <div class="col form-group">
                <label>Country<span style="color: red;">*</span></label>
                <select class="form-control" name="countryCode"  id="countryCode" required >
                    <option  value="India">India</option>
                    <option  value="China">China</option>
                    <option  value="Japan">Japan</option>
                </select>
            </div>
            <!-- <div class="col form-group" *ngIf="!isStateDisabled">
                <label>State<span style="color: red;">*</span></label>
                <select class="form-control" name="stateCode"  [(ngModel)]="user.stateCode" #stateCode="ngModel" id="stateCode" required>
                    <option *ngFor="let item of stateAny" value={{item.id}}>{{item.name}}</option>
                </select>            
            </div> -->
            <!-- <div class="col form-group" >
              <label>State<span style="color: red;">*</span></label>
              <select class="form-control" name="stateCode"  id="stateCode" disabled>
                  <option  value={{item.id}}>{{item.name}}</option>
              </select>            
          </div> -->
        </div>
        <div>
            <div>
                <input type="radio" id="male" name="gender" value="male">
<label for="male">Male</label><br>
<input type="radio" id="female" name="gender" value="female">
<label for="female">Female</label><br>
<input type="radio" id="other" name="gender" value="other">
<label for="other">Other</label>
            </div>
        </div>
        <div>
            <div>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
<label for="vehicle1"> I have a bike</label><br>
<input type="checkbox" id="vehicle2" name="vehicle2" value="Car">
<label for="vehicle2"> I have a car</label><br>
<input type="checkbox" id="vehicle3" name="vehicle3" value="Boat">
<label for="vehicle3"> I have a boat</label><br>
            </div>
        </div>
     
        
       
       
        <div class="form-group">
            <button type="submit" class="btn btn-primary btn-block" [disabled]="!signupForm.valid" > Register  </button>
            <i  class="fa fa-spinner fa-spin" style="font-size:24px"></i>
        </div> <!-- form-group// -->      
        <small class="text-muted">By clicking the 'Sign Up' button, you confirm that you accept our <br> Terms of use and Privacy Policy.</small>                                          
    </form>
    </article> <!-- card-body end .// -->
    <div class="border-top card-body text-center">Have an account? <a routerLink='/signin'>Log In</a></div>
    </div> <!-- card.// -->
    </div> <!-- col.//-->
    
    </div>

  <a href="https://www.w3schools.com" target="_blank">Visit W3Schools.com!</a> 