import { Component, OnInit } from '@angular/core';
import { general } from 'src/app/Models/general';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BlogService } from 'src/app/services/blog/blog.service';
import  swal from 'sweetalert';

@Component({
  selector: 'app-addimage',
  templateUrl: './addimage.component.html',
  styleUrls: ['./addimage.component.css']
})
export class AddimageComponent implements OnInit {

  general:general;
  form: FormGroup;
  constructor( private fb: FormBuilder, private blogServices:BlogService) {
    this.form = this.fb.group({
      avatar: null
    });
   }

  ngOnInit() {
    this.general=new general();
  }

  onFileChange(event) {
    //swal("selected");
    if (event.target.files.length > 0) {
      let file = event.target.files[0];
      this.form.get('avatar').setValue(file);

      console.log(this.form.get('avatar').value);
    }
  }
  uploadDocument(dtype:string) {
    debugger;
    let input = new FormData();
    input.append('avatar', this.form.get('avatar').value);
   
    input.append('documentType', this.general.data);
    this.blogServices.UploadImage(input).subscribe(
      (data: any) => {
        if (data == true) {
          
          swal("File uploaded sucessfully");
        }
        else {
          swal("Error occurred!");
        }
        
      }
      
    );
   
  }

}
