import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import  swal from 'sweetalert';
import {user} from '../Models/user';
import { distinctUntilChanged } from 'rxjs/operators';
import { JwtService } from './Authentication/jwt.service';
import { isPlatformServer, isPlatformBrowser } from '@angular/common';




export interface UserAccessToken {
  AccessToken: string;
}
@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly rootUrl = "https://engineerdiaries.com:44553/";

  public isUserLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentUserSubject = new BehaviorSubject<user>({} as user);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
  public globalUser:BehaviorSubject<user> = new BehaviorSubject<user>(null);


  constructor(private http: HttpClient,private jwtService:JwtService) {
    this.setLoginStatus();
   }

  
  setAuth(user:user) {
    debugger; 
    this.isUserLoggedIn.next(true);
    // Set current user data into observable
    this.currentUserSubject.next(user);
    this.globalUser.next(user);   
  }
  registerUser(form)
  {
    debugger;
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'user/registerUser',form,requestOptions);
  }
  signin(form)
  {
    debugger;
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.post(this.rootUrl + 'user/signin',form,requestOptions);
  }
  setLoginStatus() {
    if(typeof window !== 'undefined')
    
    {
      if(localStorage.getItem("userToken") != null)
      //swal("token found");
      this.isUserLoggedIn.next(true);
    }
    else
    {
      this.isUserLoggedIn.next(false);
    }
  }
  contactForm(form)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    return this.http.post(this.rootUrl + 'user/contactForm',form,requestOptions);
 

  }
  newsletter(form)
  {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    return this.http.post(this.rootUrl + 'user/newsletterForm',form,requestOptions);
 
  }
  getEmptyUser() {
    const headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'No-Auth': 'True'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    return this.http.get(this.rootUrl + 'user/getEmptyUser',requestOptions);
  }
  purgeAuth() {

    debugger;
    this.isUserLoggedIn.next(false);
    this.jwtService.destroyToken();
    this.currentUserSubject.next({} as user);
  }
  getCurrentUserData()
  {
    // const headerDict = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    //   'No-Auth': 'True'
    // };
    // const requestOptions = {
    //   headers: new HttpHeaders(headerDict),
    // };
    // var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
     return this.http.get(this.rootUrl + 'user/getCurrentUserData');
  }
}
