import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { user } from 'src/app/Models/user';
import  swal from 'sweetalert';
import { Router } from '@angular/router';
import { JwtService } from 'src/app/services/Authentication/jwt.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  constructor(private userService:UserService,private router:Router,private jwtServices:JwtService) { }
  user:user;
  isLoading:boolean=false;
  ngOnInit() {
    this.userService.isUserLoggedIn.next(false);
    this.user=new user();
  }
  signin()
  {
    
      this.isLoading=true;
      this.userService.signin(this.user).subscribe(
        (data:any)=>
        {
          this.isLoading=false;
          if(data!=false)
          {
            //successful
            console.log("SIGNIN");
            console.log(data);
            console.log(data.accessToken);
            this.jwtServices.saveToken(data.accessToken);
            this.userService.isUserLoggedIn.next(true);
            this.userService.setAuth(data);
            swal("Successfully Signed In");
            this.router.navigate(['/home']);
          }
         
          else 
          {
            swal("Incorrect credentials!");

          }
         
        })
     
  
  
  }

}
