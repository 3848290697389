<div id="footer" class="container-fluid">
    <div class="container">
        <!-- <div class="row justify-content-between">
           
                     <div class="col-2"><a routerLink='/home' routerLinkActive='active' >Home</a></div>
            <div class="col-2"><a routerLink='/about'>About</a></div>
            <div class="col-2"><a routerLink='/newsletter'>Newsletter</a></div>
            
            <div class="col-2"><a [routerLink]="['/contact']">Contact Us</a></div>
            <div class="col-2"><a routerLink='/blogs' >Blogs</a></div>
        </div> -->

        <!-- <div class="row justify-content-between">
           
            <div class="col-2"><a routerLink='/home' routerLinkActive='active' >Home</a></div>
   <div class="col-2"><a routerLink='/about'>About</a></div>
   <div class="col-2"><a routerLink='/newsletter'>Newsletter</a></div>
   
   <div class="col-2"><a [routerLink]="['/contact']">Contact Us</a></div>
   <div class="col-2"><a routerLink='/blogs' >Blogs</a></div>
</div> -->
                <!-- <ul class="list-unstyled quick-links">
                    <li><a routerLink='/home' routerLinkActive='active' ><i class="fa fa-angle-double-right"></i>Home</a></li>
                    <li><a routerLink='/about'><i class="fa fa-angle-double-right"></i>About</a></li>
                    <li><a routerLink='/newsletter'><i class="fa fa-angle-double-right"></i>Subscribe To Newsletter</a></li>
                  </ul> -->
            
        
            <!-- <div class="col-12 ">
                <ul class="list-unstyled quick-links">
                    <li><a routerLink='/contact'><i class="fa fa-angle-double-right"></i>Contact Us</a></li>
                    <li><a routerLink='/blogs' ><i class="fa fa-angle-double-right"></i>Blogs</a></li>
                </ul>
            </div> -->
          

      
       
        <div class="row justify-content-between">
            <div class="col-3"><a href="https://www.facebook.com/lovebeingengineer"><i class="fa fa-facebook"></i></a></div>
            <div class="col-3"><a href="https://twitter.com/Diariesengineer"><i class="fa fa-twitter"></i></a></div>
            <div class="col-3"><a href="https://www.instagram.com/engineerdiaries101/"><i class="fa fa-instagram"></i></a></div>
            <div class="col-3"><a href="mailto:engineerdiaries@gmail.com" target="_blank"><i class="fa fa-envelope"></i></a></div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                <ul class="list-unstyled list-inline social text-center">
                    <li class="list-inline-item"><a href="https://www.facebook.com/lovebeingengineer"><i class="fa fa-facebook"></i></a></li>
                    <li class="list-inline-item"><a href="https://twitter.com/Diariesengineer"><i class="fa fa-twitter"></i></a></li>
                    <li class="list-inline-item"><a href="https://www.instagram.com/engineerdiaries101/"><i class="fa fa-instagram"></i></a></li>
                    <li class="list-inline-item"><a href="mailto:engineerdiaries@gmail.com" target="_blank"><i class="fa fa-envelope"></i></a></li>
                </ul>
            </div> -->
            <hr>
        </div>	
        <div class="row">
            <div class="col-lg-12">
                <p> Engineer Diaries - Software Testing Courses in India</p> 
                <p class="h6">© All right Reserved to Engineer Diaries</p>
            </div>
            <hr>
        </div>	
    </div>
</div>
<!-- ./Footer -->